import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Layout } from '../component/Layout'
import { PropertyDetails } from '../component/page_component/PropertyDetails'
import { PropertyDisplay } from '../component/page_component/PropertyDisplay'
import { PropertyExtraDetails } from '../component/page_component/PropertyExtraDetails'
import Agent from '../component/page_component/Agent'
import AgentContactForm from '../component/page_component/AgentContactForm'
import PropertySlider from '../component/page_component/PropertySlider'
import PropertyGallery from '../component/page_component/PropertyGallery'
import PropertyMap from '../component/page_component/PropertyMap'
import PropertyFooter from '../component/page_component/PropertyFooter'
import PropertyBottomBar from '../component/page_component/PropertyBottomBar'
import { useIntl } from 'gatsby-plugin-intl'
import { capitalize } from '../utils/helper'
import { STATUS_A_LOUER, STATUS_SAISONNIER } from '../utils/constants'
import CustomArrow from '../component/page_component/Display/CustomArrow'
import Carousel from 'react-multi-carousel'
import { PropertyBox } from '../component/DetailBox/PropertyBox'
import PropertyDialog from '../component/page_component/PropertyDialog'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { getCookieValue } from '../utils/cookies'
import hreflangConfig from '../utils/hreflangConfig'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  boxWrapper2: {
    position: 'absolute',
    bottom: '3rem',
    zIndex: 110,
    maxWidth: '1920px',
    margin: 'auto',
    paddingLeft: '24px',
    left: 0,
    right: 0,
    [theme.breakpoints.between('md', 'md')]: {
      display: 'none',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'none',
    },
  },
  imgFullSize: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  sliderDiv: {
    zIndex: 1000,
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      height: '70vh',
    },
    position: 'relative',
    top: '70px',
    overflow: 'hidden',
    width: '100%',
  },
  carouselImg: {
    height: '100vh',
    maxWidth: '100vw',
    margin: 'auto',
    objectFit: 'contain',
    display: 'block',
  },
  scollableImg: {},

  icon: {
    opacity: 0.5,
    width: '12rem',
    height: 'auto',
    [theme.breakpoints.between('md', 'md')]: {
      width: '8rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '4rem',
    },
  },
}))

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
}

const Property: React.FC<any> = (props) => {
  const { location, pageContext: data } = props

  const {
    autreVille,
    infos,
    isOffmarket,
    isProtected, 
    lat,
    lng,
    nombreChambres,
    nombrePieces,
    photos,
    pk,
    prixAffichageFai,
    prixConfidentiel,
    prixLoyer,
    prixSaisonnierMax,
    prixSaisonnierMin,
    propertyTypeDisplay,
    reference,
    statutVente,
    suiviPar,
    surfaceHabitable,
    titreFr,
    unitArea,
    unitPrice,
    video,
    ville,
    visiteVirtuelle,
    propertyType,
  } = data

  const srcSets = photos.map(({ large: src }: any, index: number) => ({ src, alt: index }))
  const classes = useStyles()
  const intl = useIntl()
  const [sliderOpen, setSliderOpen] = useState(false)
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const cookieName = 'kretz-cookie-consent'
  const [cookieValue, setCookieValue] = useState(undefined)
  const title = data[`titre${capitalize(intl.locale)}`]
    ? data[`titre${capitalize(intl.locale)}`]
    : titreFr
    ? titreFr
    : infos
  const scrollRef = useRef()
  useEffect(() => {
    setCookieValue(getCookieValue(cookieName))
  }, [])

  let propertyTypeUrl
  switch (propertyType) {
    case 'APP':
      propertyTypeUrl = 'appartement'
      break
    case 'HOT':
      propertyTypeUrl = 'hotel-particulier'
      break
    case 'MAI':
      propertyTypeUrl = 'maison-villa'
      break
    case 'CHA':
      propertyTypeUrl = 'chateau-manoir'
      break
    case 'PAR':
      propertyTypeUrl = 'parcelle'
      break
    case 'VIL':
      propertyTypeUrl = 'maison-villa'
      break
    case 'LOF':
      propertyTypeUrl = 'immeuble'
      break
    case 'IMM':
      propertyTypeUrl = 'immeuble'
      break
    case 'CHL':
      propertyTypeUrl = 'chalet'
      break
  }

  const getHreflangLinks = (config, reference, type) => {
    const baseKey = '/fr/annonce'
    const baseLinks = config[baseKey] || {}

    const hreflangLinks = {}

    Object.keys(baseLinks).forEach((lang) => {
      hreflangLinks[lang] = `${baseLinks[lang]}/${reference.toLowerCase()}/${type}/`
    })

    return hreflangLinks
  }

  const hreflangLinks = getHreflangLinks(hreflangConfig, reference, propertyTypeUrl)
  const metaDescription = `${propertyTypeDisplay} à vendre à ${ville} - Kretz, disponible sur Kretz, agence immobilière de luxe. Découvrez ce bien d'exception. Contactez-nous maintenant !`
  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const canonicalUrl = `${dataCanonical.site.siteMetadata.siteUrl}${location.pathname}`
  return (
    <Layout
      location={location}
      showFlotingMenu={false}
      maxWidth={false}
      title={(title || propertyTypeDisplay) + ' - Kretz'}
    >
      {!isOffmarket && !isProtected && (
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
          {hreflangLinks &&
            Object.keys(hreflangLinks).map((lang) => (
              <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
            ))}
          <meta name="description" content={metaDescription} />
          <title>{title || propertyTypeDisplay} - Kretz</title>
        </Helmet>
      )}
      <div className={classes.sliderDiv}>
        <CustomArrow onClick={() => setSliderOpen(true)} height="100%" fontSize="2rem" />
        <img
          src={srcSets[0]?.src}
          alt="Image Kretz principale du bien"
          className={classes.imgFullSize}
          loading="lazy"
        />

        <div className={classes.boxWrapper2}>
          <PropertyBox
            confidentialPrice={prixConfidentiel}
            title={propertyTypeDisplay}
            unitPrice={unitPrice}
            price={
              STATUS_A_LOUER.includes(statutVente)
                ? prixLoyer
                : STATUS_SAISONNIER.includes(statutVente)
                ? prixSaisonnierMin
                : prixAffichageFai
            }
            priceMax={STATUS_SAISONNIER.includes(statutVente) ? prixSaisonnierMax : null}
            unitArea={unitArea}
            area={`${surfaceHabitable} ${
              unitArea == 'M2'
                ? intl.formatMessage({ id: 'squareMeter' })
                : intl.formatMessage({ id: 'squareFeet' })
            }`}
            ville={autreVille || ville}
            reference={reference}
            size={`${nombreChambres} ${intl.formatMessage({
              id: 'chamber' + (nombreChambres && nombreChambres > 1 ? 's' : ''),
            })}`}
            pieces={`${nombrePieces} ${intl.formatMessage({
              id: 'piece' + (nombrePieces && nombrePieces > 1 ? 's' : ''),
            })}`}
            isRented={STATUS_A_LOUER.includes(statutVente)}
            isSeasonal={STATUS_SAISONNIER.includes(statutVente)}
          />
        </div>
      </div>
      <PropertyDialog isOpen={sliderOpen} close={() => setSliderOpen(false)}>
        <Carousel
          customLeftArrow={<CustomArrow side="left" height="100%" fontSize="2rem" />}
          customRightArrow={<CustomArrow height="100%" fontSize="2rem" />}
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={false}
          autoPlaySpeed={5000}
          centerMode={false}
          focusOnSelect={false}
          infinite
          showDots
          dotListClass="custom-dot-list"
          keyBoardControl
          minimumTouchDrag={10}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
        >
          {srcSets.map((srcSet: { src: string | undefined; alt: string | undefined }) => (
            <div key={srcSet.alt + '_divCarouselImg'} className={classes.scollableImg}>
              <img
                key={srcSet.alt + '_imglinksTextlinksTextCarouselImg'}
                src={srcSet.src}
                alt={srcSet.alt}
                className={classes.carouselImg}
                loading="lazy"
              />
            </div>
          ))}
        </Carousel>
      </PropertyDialog>
      <PropertyDetails
        confidentialPrice={prixConfidentiel}
        title={title}
        pk={pk}
        area={`${surfaceHabitable} ${
          unitArea == 'M2'
            ? intl.formatMessage({ id: 'squareMeter' })
            : intl.formatMessage({ id: 'squareFeet' })
        }`}
        city={ville}
        reference={reference}
        price={
          STATUS_A_LOUER.includes(statutVente)
            ? prixLoyer
            : STATUS_SAISONNIER.includes(statutVente)
            ? prixSaisonnierMin
            : prixAffichageFai
        }
        priceMax={STATUS_SAISONNIER.includes(statutVente) ? prixSaisonnierMax : null}
        size={`${nombreChambres} ${intl.formatMessage({
          id: 'chamber' + (nombreChambres && nombreChambres > 1 ? 's' : ''),
        })}`}
        pieces={`${nombrePieces} ${intl.formatMessage({
          id: 'piece' + (nombrePieces && nombrePieces > 1 ? 's' : ''),
        })}`}
        details={data[`description${capitalize(intl.locale)}`]}
        photo={srcSets[0]?.src}
        isRented={STATUS_A_LOUER.includes(statutVente)}
        isSeasonal={STATUS_SAISONNIER.includes(statutVente)}
        isOffmarket={!isOffmarket ? reference : ''}
        unitPrice={unitPrice}
        agent={suiviPar}
        scrollRef={scrollRef}
        filmedSrc={video}
      />
      {cookieValue && cookieValue['vimeo-third-party-cookies-allowed'] == '1' && (
        <PropertyDisplay visitSrc={visiteVirtuelle} />
      )}
      <PropertyExtraDetails data={data} />
      {suiviPar && <Agent agent={suiviPar} />}
      {suiviPar && (
        <div ref={scrollRef}>
          <AgentContactForm reference={reference} emailTo={suiviPar && suiviPar.email} />
        </div>
      )}
      <PropertySlider />
      <PropertyGallery srcs={srcSets} />
      <PropertyMap
        lat={!lat ? Number.parseFloat('48.8566') : Number.parseFloat(lat)}
        lng={!lng ? Number.parseFloat('2.3522') : Number.parseFloat(lng)}
        isSmDown={isSmDown}
      />
      <PropertyFooter />
      <PropertyBottomBar pk={pk} reference={reference} title={title} isOffmarket={isOffmarket} />
    </Layout>
  )
}

export default Property
